<!--
* @Description: 二维码
* @Author: sunchunchun
* @CreateDate: 2024/03/18 15:42:32
* @FileName: index.vue
!-->
<template>
    <div style="width: 100%; height: 100%;" class="box-qrcode">
        <canvas v-show="code == 200" height="100%" width="100%" style="width: 100%; height: 100%;" ref="qrcode"
            id="qr-header-one"></canvas>
        <div v-show="code == 500" class="err-box">
            <img src="../../../assets/images/qrcode-err.png" alt="">
            <span>{{ msg }}</span>
        </div>
    </div>
</template>

<script>
import QRCode from "qrcode"
import { generateQRcode } from "@/api/cusManagerlogin/index"
export default {
    name: "QRCode",
    props: {
        lng: {
            type: Number,
            default: 200
        }
    },
    data() {
        return {
            url: "",
            options: {
                errorCorrectionLevel: "H", // 容错级别
                quality: 0.3, // 二维码质量
                margin: 6, // 二维码留白边距
                width: 0,
                height: 0,
                color: {
                    dark: '#000',
                    light: '#fff'
                }
            },
            code: 0,
            msg: '123'
        }
    },
    created() {
    },
    mounted() {
        setTimeout(() => {
            this.options.width = this.lng
            this.options.height = this.lng
            this.onLoad()
        }, 100)
    },
    methods: {
        /**
         * 初始化
         */
        onLoad() {
            generateQRcode({ goodsId: this.$route.query.id }).then(res => {
                if (res.code == 200) {
                    // amount 不为null时则表示当前次数
                    if (res.data.amount != null) {
                        this.$dialog({
                            confirmButtonText: '确定',
                            confirmButtonColor: '#1989fa',
                            message: `本机构可评级户数仅剩${res.data.amount}户，为保证业务正常开展，请及时充值。`,
                        })
                    }
                    this.opened(res.data.codeId)
                } else {
                    this.$toast(res.msg)
                }
                this.code = res.code
                this.msg = res.msg
            })
        },
        opened(id) {
            let dom = this.$refs.qrcode
            // 测试环境
            let hostName = window.location.hostname
            let protocol = window.location.protocol
            if (hostName && hostName.indexOf('localhost') == -1 && hostName.indexOf('10.0.48.5') == -1) {
                this.url = protocol + '//' + hostName + '/?id=' + id + '&goosId=' + this.$route.query.id
            } else {
                this.url = 'https://xcxtest.sdsdbjt.com/?id=' + id + '&goosId=' + this.$route.query.id
            }
            let _this = this
            console.log(this.url)
            QRCode.toCanvas(dom, this.url, this.options, function (error) {
                if (error) {
                    _this.$toast("二维码加载失败，请联系管理员！")
                }
            })
        },
    }
}
</script>

<style lang='scss' scoped>
.box-qrcode {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        margin-bottom: 10px;
        width: 100px;
        height: 100px;
    }

    span {
        font-size: 26px;
    }
}

.err-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
</style>