<!--
* @Description: 登录
* @Author: sunchunchun
* @CreateDate: 2022/09/14 16:48:32
* @FileName: login.vue
!-->
<template>
    <div class="login-bg">
        <div class="bac-index">
            <div class="bac-img">
                <div class="center-logo">
                    <img src="../../assets/images/logo.png" alt="">
                </div>
                <div class="center-title">鲁担惠企通</div>
                <div class="center-btn">
                    <van-button @click="onPersonal" class="footer-btn1" round type="info">
                        <van-icon class="btn-icon" />个人登录
                    </van-button>
                    <van-button @click="onEnterprise" class="footer-btn2" round type="info">
                        <van-icon class="btn-icon" />企业登录
                    </van-button>
                        <a @click="onRegistered" class="ben-a">暂无账号？立即注册</a>
                        <div class="bt-boorder"></div>
                        <a @click="onmanager" class="footer-btn4">客户经理登录</a>
                </div>
                
                <!-- <van-button @click="goPay" class="footer-btn1" round type="info">
                    <van-icon class="btn-icon" />测试支付(禁止点击)
                </van-button> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { login } from "@/api/user/index"
import storage from 'store'
export default {
    computed: {
        ...mapActions(['Login']),
        ...mapGetters(['token'])
    },
    data () {
        return {
            codeId: '',
            goosId: '',
        }
    },
    activated() {
    },
    created() {
        let query = this.$route.query
        if (query && query.codeId != '' && query.codeId != undefined && query.codeId != null) {
            this.codeId = query.codeId
        }
        if (query && query.goosId != '' && query.goosId != undefined && query.goosId != null) {
            this.goosId = query.goosId
        }
    },
    methods: {
        goPay() {
            
            window.wx.miniProgram.navigateTo({
                url: "/pages/pay/index",
            })
        },
        // 个人登录
        onPersonal() {
            this.$router.push({
                path: '/personal',
                query: {
                    type: '1',
                    codeId: this.codeId,
                    goosId: this.goosId
                }
            })
        },
        // 企业登录
        onEnterprise() {
            this.$router.push({
                path: '/personal',
                query: {
                    type: '2',
                    codeId: this.codeId,
                    goosId: this.goosId
                }
            })
            //this.$router.push({path: '/enterprise'})
        },
        // 客户经理登录
        onmanager() {
            this.$router.push({path: '/cusManagerlogin'})
        },
        // 暂不登录
        onNotLogin() {
            // this.$store.commit('CHANGE_TAB_ACTIVE', '/notloggedhome/index')
            this.$router.go(-1)
        },
        // 跳转注册
        onRegistered() {
            storage.set('setQ', this.codeId)
            storage.set('setGoosId', this.goosId)
            this.$router.push({ path: '/registered' })
        },
        // 机构登录
        onJgLogin() {
            this.$router.push({ path: '/institutions' })
        },
    }
}
</script>

<style lang='scss' scoped>
.login-bg{
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/login-bg.png');
    background-size: cover;
    background-repeat:no-repeat;
    background-size: cover;
}
.user-bac-one{
    background-image:none;
}
.bt-boorder{
    width: 2px;
    height: 32px;
    background-color: #8b6841;
    margin-left: 18px;
    display: inline-block;
}
.btn-icon-not{
    background: #b8babc;
}
.bac-index{
    width: 100%;
    height: 100%;
    // background-image: linear-gradient(to bottom , #fff, #D5E9F7);
    .bac-img{
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .center-title{
        color: black;
        // font-family: Cursive;
        margin-top: 5%;
        font-size: 50px;
        text-align: center;
        font-weight: 500;
    }
    .center-logo{
        margin: 42% 10% 0 10%;
        text-align: center;
        img{
            width: 140px;
            height: 150px;
        }
    }
    .center-btn{
        margin: 15% 10% 0;
        text-align: center;
        .btn-icon{
            font-size: 36px;
        }
        .footer-btn1{
            width: 100%;
            border: none;
            padding: 50px 0;
            font-size: 36px;
            margin-top: 8%;
            background: white;
            color:black;
        }
        .footer-btn2{
            width: 100%;
            border: none;
            padding: 50px 0;
            font-size: 36px;
            margin-top: 10%;
            background: white;
            //box-shadow: 0px 10px 18px 0px rgba(84, 228, 216, 0.43);
            color:black;
        }
        .footer-btn3{
            width: 100%;
            border: none;
            padding: 50px 0;
            font-size: 36px;
            margin-top: 10%;
            background: #b8babc;
            box-shadow: 0px 10px 18px 0px rgba(178, 180, 180, 0.43);
        }
        // 机构登录
        .jg-login{
            margin-top: 80px;
            width: 100%;
            text-align: center;
            color: #3B90DD;
            font-size: 30px;
        }
        .ben-a{
            margin-top: 9%;
            margin-left: -5%;
            font-size: 30px;
            text-align: center;
            color: #8b6841;
            padding-bottom: 15px;
            display: inline-block;
        }
        .footer-btn4{
            margin-top: -65%;
            margin-left: 4%;
            font-size: 30px;
            color: #8B6841;
        }
    }
}
</style>