<!--
* @Description: 预审card  申请中
* @Author: sunchunchun
* @CreateDate: 2023/12/15 15:56:08
* @FileName: YsList.vue
!-->
<template>
    <div>
        <div class="card" v-for="(row, index) in data" :key="index">
            <template v-if="row.acceptNo">
                <div class="fr-title">山东省融资担保有限公司保函业务</div>
                <div class="form-area">
                    <div class="row">
                        <div class="label">投标人名称：</div>
                        <div class="download">{{ row.enterpriseName }}</div>
                    </div>
                    <div class="row">
                        <div class="label">招标人名称：</div>
                        <div class="download">{{ row.tenderer }}</div>
                    </div>
                    <div class="row">
                        <div class="label">招标项目名称：</div>
                        <div class="download">{{ row.projectName }}</div>
                    </div>
                    <div class="row">
                        <div class="label">申请时间：</div>
                        <div class="download">{{ row.applyTime }}</div>
                    </div>
                    <div class="row">
                        <div class="label">受理流水号：</div>
                        <div class="download">{{ row.acceptNo }}</div>
                    </div>
                    <div class="row">
                        <div class="label">业务进展：</div>
                        <u class="download dif" @click="goState(row.applyStage, row.acceptNo, row.generateeLetterNo)">{{
                            returnStatus(row.applyStage)
                        }}</u>
                    </div>
                </div>
            </template>
            <template v-else>
                <div>
                    <span v-if="row.productType == 1"
                        style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">担保申请</span>
                    <span v-if="row.productType == 2"
                        style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">普惠区域担保业务</span>
                    <span v-if="row.productType == 3"
                        style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">普惠担保申请</span>
                    <span v-if="row.productType == 4"
                        style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">鲁岗贷业务</span>
                    <span v-if="row.productType == 5"
                        style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">省农信联社业务</span>
                    <span v-if="row.productType == 6"
                        style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">莱商银行业务</span>
                    <span v-if="row.productType == 7"
                        style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">鲁担科技贷</span>
                    <span v-if="row.productType == 8"
                        style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">鲁担科技贷(保后管理)</span>
                    <span v-if="row.productType == 9"
                        style="font-size: 18px;font-family: TITLEKAITI;font-weight: bold;">工商银行业务</span>
                    <van-divider />
                </div>
                <div>
                    <div class="title">借款人</div>
                    <div>{{ row.name }}</div>
                    <van-divider />
                </div>
                <div>
                    <div class="title">申请时间</div>
                    <div>{{ row.date }}</div>
                    <van-divider />
                </div>
                <div>
                    <div class="title">申请编号</div>
                    <div>{{ row.id }}</div>
                    <van-divider />
                </div>
                <div>
                    <div class="title">业务进度</div>
                    <div>
                        <span>{{ row.title }}</span>
                    </div>
                    <van-divider />
                </div>
                <div v-if="row.state == 1" style="text-align: center;">
                    <div class="btn-box" style="display:flex">
                        <div class="footer-btn font-family-all" style="margin-left:-48%;background:#EAEAEA;color:#000"
                            @click="onCancel(row.id, row.productType)"><span>取消</span></div>
                        <div @click="onSup(row)" style="margin-left:13%" class="footer-btn font-family-all">
                            <span>补充资料</span></div>
                    </div>
                </div>
                <!-- 错误状态说明 -->
                <div v-if="row.authorisationStatus == 1">
                    <div class="err-text" v-for="(item, index) in dictList" :key="index">
                        <span v-if="row.authorisationErrorReason == item.value">{{ item.name }}</span>
                    </div>
                </div>
                <!-- 下一步 -->
                <div v-if="row.state == 5 || row.state == 8 " style="text-align: center;margin-top: 10px;">
                    <div class="btn-box">
                        <div @click="onNext(row)" class="footer-btn font-family-all"><span>下一步</span></div>
                    </div>
                </div>
                <!-- 重新授权 -->
                <div v-if="row.authorisationStatus == 1 && row.state == 6" style="text-align: center">
                    <!-- <div class="btn-box btn-inline">
                    <div @click="onCel(row)" class="footer-btn-cel font-family-all"><span>取消信用评级</span></div>
                </div> -->
                    <div class="btn-box btn-inline">
                        <div @click="onEmpower(row)" class="footer-btn-submit font-family-all"><span>重新授权</span></div>
                    </div>
                </div>
                <!-- 8 -->
                <!-- <div v-if="row.authorisationStatus == 1 && row.state == 8">
                <div class="btn-box btn-inline">
                    <div @click="onEmpower(row)" class="footer-btn-submit font-family-all"><span>重新授权</span></div>
                </div>
            </div> -->
            </template>
        </div>
    </div>
</template>

<script>
import { getUrl, cancelAuth, reAuth } from "@/api/task/YsList/YsListProgress"
import { getAcceptNoDetail } from "@/api/feiRong/index";
export default {
    name: "YsListProgress",
    props: {
        data: {
            type: Array,
            default: [{}]
        },
    },
    watch: {
        data: {
            handler(newV) {
                this.item = newV
            },
            deep: true,
        }
    },
    data() {
        return {
            item: [],
            dictList: [
                { name: '授权失败，请重新授权或联系工作人员', value: '1' },
                { name: '获取数据失败，请重新授权或联系工作人员', value: '2' },
                { name: '信息采集失败，若请重新授权或联系工作人员', value: '3' },
            ],
            stateList: [
                { name: '申请保函', value: '0' },
                { name: '邀约条款', value: '1' },
                { name: '资料填写', value: '2' },
                { name: '审核中', value: '3' },
                { name: '费用缴纳', value: '4' },
                { name: '保函生成', value: '5' },
                { name: '审核未通过', value: '6' },
                { name: '第三方平台支付中', value: '7' },
                {name:'订单关闭',value:'8'}
            ],
        }
    },
    created() {
        this.item = this.data
    },
    methods: {
        // 下一步
        onNext(row) {
            getUrl({ id: row.id }).then(res => {
                if (res.code == 200) {
                    var user = navigator.userAgent
                    var isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1;
                    // var isIos = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X)/);
                    if (isAndroid) {
                        window.open(res.msg)
                    } else {
                        window.location.href = res.msg
                    }
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        //返回状态
        returnStatus(arg) {
            let only = this.stateList.filter((item) => {
                return item.value == arg
            })
            return only[0].name
        },
        //非融根据不同状态跳转不同页面
        goState(state, acceptNo, generateeLetterNo) {
            if (state == 1 || state == 2) {
                this.$router.push({
                    name: 'bidApplicate',
                    query: {
                        state: state,
                        acceptNo: acceptNo,
                        generateeLetterNo: generateeLetterNo
                    }
                })
            } else if (state == 3) {
                this.$router.push({
                    name: 'bidResult',
                    query: {
                        state: state,
                        acceptNo: acceptNo,
                    }
                })
            } else if (state == 4) {
                this.$router.push({
                    name: 'payBank',
                    query: {
                        acceptNo: acceptNo,
                    }
                })
            } else if (state == 5) {
                this.$router.push({
                    name: 'letterDownload',
                    query: {
                        acceptNo: acceptNo,
                    }
                })
            } else if (state == 6) {
                this.$router.push({
                    name: 'bidResult',
                    query: {
                        state: state,
                    }
                })
            } else if (state == 7) {
                this.$router.push({
                    name: 'bidResult',
                    query: {
                        state: state,
                    }
                })
            } else if (state == 8) {
                this.$router.push({
                    name: 'bidResult',
                    query: {
                        state: state,
                    }
                })
            }
            //     READAGREEMENT("1", "邀约条款"),
            //     RICHINFO("2", "资料填写"),
            //     AUDITING("3", "审核中"),
            //     PAYMENT("4", "费用缴纳"),
            //     LETTER("5", "保函生成"),
            //     REFUSED("6", "审核未通过"),
            //     THIRDPAY("7", "第三方平台支付中"), ('8','订单关闭')    
        },
        // 取消授权
        onCel(row) {
            cancelAuth({ id: row.id }).then(res => {
                if (res.code == 200) {
                    this.$emit('list', 0)
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        // 重新授权
        async onEmpower(row) {
            await reAuth({ id: row.id }).then(res => {
                if (res.code == 200) {
                    this.$emit('list', 0, 1)
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        // 执行父组件补充资料
        onSup(row) {
            this.$emit('progress', row)
        },
        // 执行父组件取消
        onCancel(id, type) {
            this.$emit('onCancel', id, type)
        }
    }
}
</script>

<style lang='scss' scoped>
.card {
    font-size: 26px;
    box-shadow: 0px 2px 9px 0px rgba(218, 205, 189, 0.5);

    .title {
        margin-bottom: 20px;
        color: #888888;
    }

    background: #fff;
    margin: 10px 0 20px 0;
    padding: 25px 30px;
    border-radius: 20px;
    // backdrop-filter: blur(3px);
}

.fr-title {
    font-size: 36px;
    color: #212121;
}

.form-area {
    color: #212121;
    padding-top: 16px;

    .row {
        display: flex;
        align-items: center;
        font-size: 28px;
        line-height: 80px;
        border-bottom: 2px solid #f5f5f5;

        .label {
            color: #898989;
        }

        .download {}

        .dif {
            color: #8b6841;
        }
    }
}

.btn-box {
    width: 100%;
    text-align: center;
    position: relative;

    .footer-btn {
        font-size: 26px;
        height: 66px;
        width: 40%;
        left: 50%;
        margin-left: -22%;
        color: #fff;
        //background-image: url('../../../assets/images/newImg/button-homex.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        background: #BEA588;
        box-shadow: 0px 2px 12px 0px rgba(232, 208, 175, 0.2);
        border-radius: 44px;

        /** 立即申请 样式 */
        span {
            font-size: 26px;
            font-family: none;
            width: 200px;
            position: absolute;
            top: 14px;
            left: 50%;
            margin-left: -100px;
        }
    }
}

.err-text {
    color: rgb(255, 72, 72);
}

.btn-inline {
    display: inline-block;
    margin-top: 30px;
    width: 50%;

    .footer-btn-submit {
        font-size: 26px;
        height: 66px;
        width: 80%;
        left: 30%;
        margin-left: -22%;
        color: #fff;
        //background-image: url('../../../assets/images/newImg/button-homex.png');
        background: #BEA588;
        background-size: cover;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        border-radius: 44px;
        box-shadow: 0px 2px 12px 0px rgba(232, 208, 175, 0.2);

        /** 立即申请 样式 */
        span {
            font-size: 26px;
            font-family: none;
            width: 200px;
            position: absolute;
            top: 14px;
            left: 50%;
            margin-left: -100px;
        }

    }

    .footer-btn-cel {
        font-size: 26px;
        height: 66px;
        width: 70%;
        left: 30%;
        margin-left: -22%;
        color: #000;
        border: #b9b9b9 1px solid;
        border-radius: 50px;
        background: #fff;
        position: relative;

        /** 立即申请 样式 */
        span {
            font-size: 26px;
            font-family: none;
            width: 200px;
            position: absolute;
            top: 14px;
            left: 50%;
            margin-left: -100px;
        }

    }
}
</style>